<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
        <div>
            <!-- <h3 class="form-subtitle mb__12">{{ $t('Unit.License') }}</h3> -->
            <FieldGroup_input
                :field-group_class="'mb__12'"
                :id="'license-key'"
                :label="$t('Unit.License key')"
                :disabled="true"
                v-model="license_key"
                v-if="!isNew"
            />
            <FieldGroup_input
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'activation-day'"
                :label="$t('Unit.Activation date')"
                :disabled="true"
                v-model="license_activation"
                v-if="!isNew"
            />
            <div class="horizontal-line mb__12" v-if="!isNew">
                <FieldGroup_input
                    :field-group_class="'field-group_width_200 mb__12'"
                    :id="'expiration-day'"
                    :label="$t('Unit.Expiration day')"
                    :disabled="true"
                    v-model="license_expired"
                />
                <button class="button button_view_icon button_theme_base ml__8"
                        v-if="showKey"
                        @click="hideLicense()"
                >
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__remove_lg"/></span> </span>
                </button>
                <button class="button button_view_icon button_theme_base ml__8"
                        v-if="!showKey"
                        @click="showLicense()"
                >
                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__add_lg"/></span> </span>
                </button>
            </div>
            <FieldGroup_kwrapper_dropdownlist
                :field-group_class="'field-group_width_200 mb__12'"
                :id="'new-license-key'"
                :label="$t('Unit.License key')"
                :items="unitsKeysFreeByProducts"
                :error="errors.addLicenseKey"
                v-model="unit.addLicenseKey"
                v-if="showKey || isNew"
            />
        </div>
    </section>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {dateFormat} from "@/filters/dateFormat";

    export default {
        name: 'UnitEdit_License.lite',
        props: [
            'value',
            'unitId',
            'errors',
        ],
        components: {},
        data() {
            return {
                interval: null,
                showKey: false
            }
        },
        computed: {
            ...mapGetters([
                "unitsKeysFreeByProducts",
                "unitsKeys",
                "unitsByIds",
                "getDateFormat",
            ]),
            isNew(){
               return !(this.unitId && this.unitId>0)
            },
            _errors(){//hack on update
                return Object.keys(this.errors)
            },
            unit(){
                return this.value
            },
            unit_license(){
                return ((this.unit || {}).license || {})
            },
            license_expired(){
                let expired = this.unit_license.expired
                let formatExpired = expired ? dateFormat(expired.date, this.getDateFormat, this.$root.$i18n.locale) : '';
                return expired ? formatExpired : ''
            },
            license_activation(){
                let activation = this.unit_license.activation
                let formatActivation = activation ? dateFormat(activation.date, this.getDateFormat, this.$root.$i18n.locale) : '';
                return activation ? formatActivation : ''
            },
            license_key(){
                return this.unit_license.key
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
                "fetchUnitsKeysFreeStatistics",
            ]),
            showLicense(){
                this.showKey = true
                this.unit.addLicenseKey = 0
                this.errors.addLicenseKey = ''
            },
            hideLicense(){
                this.showKey = false
                this.unit.addLicenseKey = 0
                this.errors.addLicenseKey = ''
            },
        },
        created() {
            // console.log('UnitEdit_Options created', this.unitId, {...this.unit})
            this.fetchUnitsKeysFreeStatistics()
        },
        updated() {
            // console.log('UnitEdit_Options updated', this.unitId, {...this.unit})
            // this.fetchUnitsKeysFreeStatistics()
        },
        mounted() {
            // console.log('UnitEdit_Options mounted', this.unitId, {...this.unit})
            this.interval = setInterval(() => {
                this.fetchUnitsKeysFreeStatistics()
            }, 10*1000)
        },
        beforeDestroy() {
            if(this.interval) {
                clearInterval(this.interval)
                this.interval = null
            }
        },
    }
</script>

<style scoped>

</style>